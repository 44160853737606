/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import React, { useEffect, useRef } from 'react';

import { Spacing } from 'src/common/ui/base/Spacing';
import { TypographyStyles } from 'src/common/ui/base/Typography';

export const Button: React.FC<
  {
    'data-testid'?: string;
    color?: MuiButtonProps['color'];
    disabled?: boolean;
    focus?: boolean;
    variant?: 'contained' | 'text';
  } & Pick<MuiButtonProps, 'color' | 'onClick' | 'endIcon' | 'startIcon'>
> = ({
  children,
  color = 'primary',
  'data-testid': dataTestid,
  disabled,
  focus,
  onClick,
  variant = 'contained',
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (focus) btnRef?.current?.focus();
  }, []);
  return (
    <MuiButton
      css={css`
        ${TypographyStyles.buttonText1}
        border-radius: 0;
        padding: ${Spacing.Unit2}px;
        line-height: 21px;
      `}
      disableElevation
      data-testid={dataTestid || 'nds-component-button'}
      disabled={disabled}
      onClick={onClick}
      ref={btnRef}
      variant={variant}
      color={color}
    >
      {children}
    </MuiButton>
  );
};
