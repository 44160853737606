import React, { ComponentPropsWithoutRef } from 'react';

import { EditEventDetails } from 'src/events/components/EditEventDetails';
import { ViewEventDetails } from 'src/events/components/ViewEventDetails';

type EditEventProps = ComponentPropsWithoutRef<typeof EditEventDetails>;
type ViewEventProps = ComponentPropsWithoutRef<typeof ViewEventDetails>;

const PreVisitDataDisplay: React.FC<
  ({ isEditing: true } & EditEventProps) | ({ isEditing?: false } & ViewEventProps)
> = ({ isEditing, ...props }) =>
  isEditing ? <EditEventDetails {...props} /> : <ViewEventDetails {...props} />;

export default PreVisitDataDisplay;
