import React, { useContext } from 'react';

import { DrugScreenData } from 'src/clinicalSnapshot/components/DrugScreenData';
import ZdTicketsData from 'src/clinicalSnapshot/components/ZdTicketsData';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { CLINICAL_SNAPSHOT_ZD_TICKETS_DATA } from 'src/featureFlags/currentFlags';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

interface ClinicalSnapshotArgs {
  patientId: string;
}
export const ClinicalSnapshot = ({ patientId }: ClinicalSnapshotArgs) => {
  const flags = useContext(FeatureFlagContext);
  return (
    <div style={{ maxWidth: 600 }}>
      <PageTitle>Clinical Snapshot</PageTitle>
      <DrugScreenData patientId={patientId} />
      {flags[CLINICAL_SNAPSHOT_ZD_TICKETS_DATA] && <ZdTicketsData patientId={patientId} />}
    </div>
  );
};
