import { makeStyles } from '@material-ui/styles';
import React from 'react';

export interface DisplayedData {
  label: string;
  value?: string | null | undefined;
  component?: React.ReactNode;
}

const DisplayData = ({ schema }: { schema: DisplayedData[] }) => {
  const styles = useStyles();
  return (
    <div className={styles.dataContainer}>
      {schema.map(({ label, value, component }) => (
        <div className={styles.dataRow}>
          <div>
            <div key={label} className={styles.rowLabel}>
              {label}
            </div>
          </div>
          <div>
            <div key={label} className={styles.fields}>
              {component || value}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default DisplayData;

const useStyles = makeStyles({
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    fontFamily: 'Nunito',
    fontWeight: 600,
    lineHeight: '17.4px',
  },
  dataRow: {
    display: 'flex',
  },
  rowLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'rgb(70, 108, 138)',
    textAlign: 'right',
    padding: '16px',
    width: '132px',
  },
  fields: {
    padding: '16px',
  },
});
