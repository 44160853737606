import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { Suspense, useContext, useEffect, useState } from 'react';

import { AccessRevokedBanner } from 'src/accessRevoked/AccessRevokedBanner';
import { getEventToolTip } from 'src/calendars';
import EventCalendar from 'src/calendars/eventCalendar';
import { ClinicalSnapshot } from 'src/clinicalSnapshot';
import FeatureFlag, { FeatureFlagNotTargeted } from 'src/components/featureflags/featureFlag';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import PatientMenu from 'src/components/general/PatientMenu/PatientMenu';
import PatientPrescribe from 'src/components/pages/erx';
import SideVisit from 'src/components/pages/eventShow/SideVisit';
import { SideVisitContext } from 'src/components/pages/eventShow/SideVisitContext';
import PatientTasks from 'src/components/pages/pageElements/PatientTasks';
import PatientDebug from 'src/components/pages/pageElements/patientDebug/PatientDebug';
import VisitNotes from 'src/components/pages/pageElements/visitNotes';
import ParticipantChat from 'src/components/pages/participantChat';
import PatientActivity from 'src/components/pages/patientActivity/PatientActivity';
import { PatientShowContext } from 'src/components/pages/patientShow/PatientShowContext';
import PatientDocuments from 'src/components/pages/patientShow/documents/patientDocuments';
import { ClinicActions } from 'src/dropInClinic/ClinicActions';
import PossibleDuplicateChartsBanner from 'src/duplicateCharts/PossibleDuplicateChartsBanner';
import { LastPatientVisitContext, MiniEvent } from 'src/events/LastPatientVisitContext';
import { getEventDisplayTitleWithoutPatient } from 'src/events/getEventDisplayTitle';
import {
  CLINICAL_SNAPSHOT,
  POC_TESTING,
  SHOW_PATIENT_PEBBLES_TABLE,
  PATIENT_CHART_SUMMARY_BAR,
} from 'src/featureFlags/currentFlags';
import PocTesting from 'src/labs/PocTesting';
import Colors from 'src/nightingale/Colors';
import { ChartUpdateContextProvider } from 'src/nightingale/components/ChartUpdateContext/ChartUpdateContext';
import LazyLoadingSpinner from 'src/nightingale/components/common/LazyLoadingSpinner/LazyLoadingSpinner';
import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import PatientOverview from 'src/nightingale/pages/PatientOverview';
import { PatientHeader } from 'src/patientHeader';
import PatientMenuV2 from 'src/patientMenu/PatientMenu';
import PatientPebbles from 'src/pebbles/pages/PatientPebbles';
import type { Provider } from 'src/shared/stores/resource';
import type { EventsStore } from 'src/stores/events';
import type { InstantiatedEvent } from 'src/stores/models/event';
import useRootStore from 'src/stores/useRootStore';
import { localZone } from 'src/util/timezones';

const ROLES_TO_REDIRECT_TO_SNAPSHOT = new Set(['clinician', 'registered_nurse']);

const PatientShow: React.FC = observer(() => {
  const rootStore = useRootStore();
  const {
    auth,
    events,
    patients,
    routerStore: {
      routerState: { params, queryParams },
    },
  } = rootStore;
  const classes = useStyles();
  const { calendar, patient } = patients;
  const { id, tab: tabParam, tabItem } = params;

  const [isLoading, setIsLoading] = useState(false);

  const flags = useContext(FeatureFlagContext);

  const teamRole = (auth.user as Provider)?.teamRole;
  const defaultTab =
    flags[CLINICAL_SNAPSHOT] && teamRole && ROLES_TO_REDIRECT_TO_SNAPSHOT.has(teamRole)
      ? 'clinicalSnapshot'
      : 'overview';
  const tab = tabParam ?? defaultTab;

  useEffect(() => {
    patients.reset();
  }, [id]);
  useEffect(() => {
    return () => patients.reset();
  }, []);

  useEffect(() => {
    const loadTab = async () => {
      await patients.loadPatientTab(id, tab, tabItem, queryParams);
    };
    if (!isLoading) {
      setIsLoading(true);
      loadTab().then(() => {
        setIsLoading(false);
      });
    }
  }, [id, tab, tabItem, queryParams]);

  const handleCreateEvent = options => {
    events.createEvent(patient, options);
  };

  const handleSaveEvent = (item, updates) => {
    events.updateEventAndSave(item, updates);
  };

  const { clearSideVisit, setSideVisit, sideVisits } = useContext(SideVisitContext);
  const sideVisitId = id ? sideVisits[id] : null;
  const { addLastPatientVisit } = useContext(LastPatientVisitContext);
  const { pageContentsRef } = useContext(PatientShowContext);

  useEffect(() => {
    loadSideVisit(sideVisitId, tab, events);
  }, [sideVisitId, tab]);

  useEffect(() => {
    updateSideVisit(tab, tabItem, id, events, setSideVisit);
  }, [id, tab, tabItem]);

  useEffect(() => {
    updateRecentVisitMenu(tab, id, events, addLastPatientVisit);
  }, [id, tab, events.event?.id]);

  useEffect(() => {
    pageContentsRef?.current?.scrollTo(0, 0);
  }, [tab]);

  if (!patient) return null;

  const timezone = patient.timezone ?? auth.user?.timezone ?? localZone;

  return (
    <div className={classes.root}>
      <div className={isVisitTab(tab) ? classes.noPrint : undefined}>
        <FeatureFlag name={PATIENT_CHART_SUMMARY_BAR}>
          <PatientMenuV2
            patientId={id}
            activeTab={tab}
            activeEventId={isVisitTab(tab) ? tabItem : undefined}
          />
        </FeatureFlag>
        <FeatureFlagNotTargeted name={PATIENT_CHART_SUMMARY_BAR}>
          <PatientMenu
            patientId={id}
            activeTab={tab}
            activeEventId={isVisitTab(tab) ? tabItem : undefined}
          />
        </FeatureFlagNotTargeted>
      </div>
      <ChartUpdateContextProvider>
        <div className={classes.mainContent}>
          <FeatureFlag name={PATIENT_CHART_SUMMARY_BAR}>
            <PatientHeader patientId={id} activeTab={tab} />
          </FeatureFlag>
          <div className={classes.columnContent}>
            <div className={classes.contents} ref={pageContentsRef}>
              <Suspense fallback={<LazyLoadingSpinner />}>
                {['erx', 'conversation'].includes(tab) === false ? (
                  <PageContainer>
                    {tab === 'overview' && <PatientOverview patientId={id} />}
                    {tab === 'clinicalSnapshot' && flags[CLINICAL_SNAPSHOT] && (
                      <ClinicalSnapshot patientId={id} />
                    )}
                    {tab === 'activity' && <PatientActivity patientId={id} />}
                    {tab === 'calendar' && (
                      <EventCalendar
                        calendar={calendar}
                        calendarType="patient"
                        events={calendar.eventInstances}
                        actionTooltip="Add event"
                        onCreate={handleCreateEvent}
                        onSave={handleSaveEvent}
                        title="Patient Calendar"
                        titleAccessor={event => getEventDisplayTitleWithoutPatient(event, timezone)}
                        tooltipAccessor={event =>
                          getEventToolTip(
                            event,
                            getEventDisplayTitleWithoutPatient(event, timezone),
                          )
                        }
                        showCheckbox
                        timezone={patient.timezone}
                        fallbackTimezone={timezone}
                      />
                    )}
                    {tab === 'documents' && <PatientDocuments patientId={id} />}
                    {tab === 'tasks' && <PatientTasks patientId={id} />}
                    {tab === 'clinicActions' && <ClinicActions patientId={id} />}
                    {tab === 'debug' && <PatientDebug data={patients.debug} patientId={id} />}
                    {isVisitTab(tab) && <VisitNotes />}
                    {tab === 'pebbles' && flags[SHOW_PATIENT_PEBBLES_TABLE] && (
                      <PatientPebbles patientId={id} />
                    )}
                    {tab === 'pocTesting' && flags[POC_TESTING] && <PocTesting patientId={id} />}
                  </PageContainer>
                ) : null}
                {tab === 'conversation' && <ParticipantChat isLoading={isLoading} />}
                {tab === 'erx' && <PatientPrescribe patientId={id} />}
              </Suspense>
            </div>
            {!isVisitTab(tab) && sideVisitId ? (
              <SideVisit onClose={() => clearSideVisit(id)} />
            ) : null}
          </div>
        </div>
      </ChartUpdateContextProvider>
      {/* Alerts */}
      <AccessRevokedBanner open={!!patient.authenticationBlocked} />
      <PossibleDuplicateChartsBanner
        patientId={id}
        firstName={patient.firstName as string}
        lastName={patient.lastName as string}
        dob={patient.dob as string}
      />
    </div>
  );
});

function isVisitTab(tab: string): boolean {
  return tab === 'events';
}

function loadSideVisit(sideVisitId: string | null, tab: string, events: EventsStore): void {
  if (!sideVisitId) return;
  if (isVisitTab(tab)) return;

  events.loadEvent(sideVisitId);
}

function updateSideVisit(
  tab: string,
  tabItem: string,
  patientId: string | null | undefined,
  events: EventsStore,
  setSideVisit: (patientId: string, eventId: string) => void,
) {
  if (!isVisitTab(tab)) return;
  const eventId = tabItem;

  const { event: maybeEvent } = events;
  if (!maybeEvent || eventId !== maybeEvent?.id) return;
  const event = maybeEvent as InstantiatedEvent;

  if (patientId && eventId) {
    const patientAttendeeId = event.patientAttendee?.id;
    if (!patientAttendeeId || patientAttendeeId !== patientId) return;
    setSideVisit(patientId, eventId);
  }
}

function updateRecentVisitMenu(
  tab: string,
  patientId: string | null | undefined,
  events: EventsStore,
  addLastPatientVisit: (patientId: string, event: MiniEvent) => void,
) {
  if (!isVisitTab(tab)) return;

  const { event: maybeEvent } = events;
  if (!maybeEvent) return;
  const event = maybeEvent as InstantiatedEvent;

  if (patientId && event) {
    if (patientId === event.patientAttendee?.id && event.id) {
      addLastPatientVisit(patientId, {
        id: event.id,
        start: event.start,
        allDay: event.allDay,
        duration: event.duration,
      });
    }
  }
}

export const __test__ = {
  updateRecentVisitMenu,
  updateSideVisit,
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    backgroundColor: Colors.ChartGray,
  },
  contents: {
    flex: 1,
    overflow: 'scroll',
    '@media print': {
      height: 'auto',
    },
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxHeight: 'calc(100vh - 64px)',
    overflow: 'hidden',
  },
  columnContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  tabs: {
    marginLeft: 15,
  },
});

export default PatientShow;
