import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import RouteLink from 'src/components/general/routeLink';
import { useStyles as usePreVisitDisplayStyles } from 'src/events/styles/preVisitDisplay.styles';

export const AttendeeList = ({ attendees }) => {
  const preVisitDisplayStyles = usePreVisitDisplayStyles();
  const styles = useStyles();
  return (
    <div className={preVisitDisplayStyles.sectionContainer}>
      <div className={preVisitDisplayStyles.sectionLabel}>Providers</div>
      <Typography variant="body2">
        {attendees.map((attendee, index) => (
          <RouteLink
            route={attendee.__typename === 'Provider' ? 'showProvider' : 'showPatient'}
            routeParams={[{ id: attendee.id }]}
            key={attendee.id}
            className={styles.routeLink}
            newWindow
          >
            {attendee.preferredFullName}
            {index + 1 < attendees.length ? ', ' : ''}
          </RouteLink>
        ))}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles({
  routeLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#212121',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
  },
});
