import { makeStyles } from '@material-ui/core/styles';

import Colors from 'src/nightingale/Colors';

export const useStyles = makeStyles(() => ({
  sectionContainer: {
    marginTop: 20,
  },
  sectionLabel: {
    color: Colors.Gray7,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 1.45,
    letterSpacing: '1.68px',
    textTransform: 'uppercase',
  },
}));
