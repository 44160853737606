import { ApolloError } from 'apollo-client';
import parseISO from 'date-fns/parseISO';

import { LOAD_DRUG_SCREENING } from 'src/clinicalSnapshot/queries/drugScreening.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { DrugScreening, TestKitOrder } from 'src/generated/gql/graphql';

export type TestKit = Pick<TestKitOrder, 'testType' | 'status'> & {
  statusDate: Date | null;
};
export type DrugScreeningData = Pick<
  DrugScreening,
  'patientId' | 'status' | 'currentPeriod' | 'hasConflictingData' | 'mitigation'
> & {
  dueDate: Date | null;
  testKitOrders: TestKit[];
};

export type UseDrugScreeningData = {
  data?: DrugScreeningData;
  error?: ApolloError;
  loading: boolean;
};

export const useDrugScreeningData = (patientId: string): UseDrugScreeningData => {
  const { data, error } = useTypedSWR([LOAD_DRUG_SCREENING, { patientId }]);

  const transformedData = data && {
    ...data.staff_getPatientDrugScreeningData,
    dueDate: data.staff_getPatientDrugScreeningData.dueDate
      ? parseISO(data.staff_getPatientDrugScreeningData.dueDate)
      : null,
    testKitOrders: data.staff_getPatientDrugScreeningData.testKitOrders
      ? data.staff_getPatientDrugScreeningData.testKitOrders.map((order: TestKitOrder) => ({
          ...order,
          statusDate: order.statusDate ? parseISO(order.statusDate) : null,
        }))
      : [],
  };

  return {
    data: transformedData,
    error,
    loading: !data && !error,
  };
};
