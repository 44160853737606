import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { ReactComponent as MedicalIcon } from 'src/shared/icons/iconMedical.svg';
import { ReactComponent as SupportIcon } from 'src/shared/icons/iconSupport.svg';

const useStyles = makeStyles({
  centeringContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    '& svg': {
      marginRight: 24,
    },
  },
  textArea: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: Colors.Taupe,

    '& .topLine': {
      fontSize: 16,
      letterSpacing: '0.201px',
    },

    '& .bottomLine': {
      textTransform: 'uppercase',
      fontSize: 12,
      letterSpacing: '1.44px',
    },
  },
});

export enum EmptyFocusCardType {
  Morning = 'morning',
  EndOfDay = 'endOfDay',
  Pause = 'pause',
  PatientBeingPrepped = 'patientBeingPrepped',
}

type EmptyFocusCardProps =
  | {
      type:
        | EmptyFocusCardType.EndOfDay
        | EmptyFocusCardType.Pause
        | EmptyFocusCardType.PatientBeingPrepped;
    }
  | {
      type: EmptyFocusCardType.Morning;
      providerName: string | null | undefined;
    };

const MessageMap = {
  [EmptyFocusCardType.EndOfDay]: () => ({
    topLine: 'You’re all done for the day!',
    bottomLine: 'Great job today!',
  }),
  [EmptyFocusCardType.Pause]: () => ({
    topLine: 'Take a moment to breathe',
    bottomLine: 'Enjoy this tranquil pause',
  }),
  [EmptyFocusCardType.Morning]: (providerName?: string | null) => ({
    topLine: providerName ? `Good morning, ${providerName}!` : `Good morning!`,
    bottomLine: 'Let’s get started!',
  }),
  [EmptyFocusCardType.PatientBeingPrepped]: () => ({
    topLine: 'A patient is almost done with their prep',
    bottomLine: 'They will be suggested to you soon',
  }),
};

export const EmptyFocusCard = (props: EmptyFocusCardProps) => {
  const classes = useStyles();
  const messageFn = MessageMap[props.type];
  const messageText =
    props.type === EmptyFocusCardType.Morning ? messageFn(props.providerName) : messageFn();
  const Icon = props.type === EmptyFocusCardType.PatientBeingPrepped ? MedicalIcon : SupportIcon;

  return (
    <div className={classes.centeringContainer}>
      <div className={classes.content}>
        <SvgIcon component={Icon} style={{ width: 48, height: 48 }} viewBox="0 0 48 48" />
        <div className={classes.textArea}>
          <div className="topLine">{messageText.topLine}</div>
          <div className="bottomLine">{messageText.bottomLine}</div>
        </div>
      </div>
    </div>
  );
};
