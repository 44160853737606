import { differenceInCalendarDays } from 'date-fns';

/**
 * Returns a string that represents the number of days ago a date was.
 * @param date The date to compare to today.
 * @returns A string that represents the number of days ago a date was.
 */
export function getDaysAgo(date: Date | null): string {
  if (!date) return '';
  const daysAgo = differenceInCalendarDays(new Date(), date);
  if (daysAgo < 0) return getDaysUntil(date);
  switch (daysAgo) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `${daysAgo} days ago`;
    case 7:
      return '1 week ago';
    case 8:
      return '1 week, 1 day ago';
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
      return `1 week, ${daysAgo - 7} days ago`;
    default:
      return `${Math.round(daysAgo / 7)} weeks ago`;
  }
}

/**
 * Returns a string that represents the number of days to go until a date.
 * @param date The date to compare to today.
 * @returns A string that represents the number of days to go until a date.
 */
export function getDaysUntil(date: Date | null): string {
  if (!date) return '';
  const daysUntil = differenceInCalendarDays(date, new Date());
  if (daysUntil < 0) return getDaysAgo(date);
  switch (daysUntil) {
    case 0:
      return 'Today';
    case 1:
      return 'Tomorrow';
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `in ${daysUntil} days`;
    case 7:
      return 'in 1 week';
    case 8:
      return 'in 1 week, and 1 day';
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
      return `in 1 week, ${daysUntil - 7} days`;
    default:
      return `in ${Math.round(daysUntil / 7)} weeks`;
  }
}
