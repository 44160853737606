import { gql } from 'src/generated/gql';

export const LOAD_PATIENT_ZENDESK_TICKETS = gql(`
  query LoadPatientZendeskTickets($patientId: ID!) {
    staff_getPatientZendeskTickets(patientId: $patientId) {
        title
        status
        lastUpdated
        assigneeProvider
        zendeskLink
    }
  }
`);
