import { useMemo } from 'react';

import { LOAD_PATIENT_ZENDESK_TICKETS } from 'src/clinicalSnapshot/queries/usePatientZendeskTickets.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';

type patientZdTicket = {
  subject: string;
  status: string;
  lastUpdated: Date;
  assignee: string;
  ticketLink: string;
};

// last updated to date value
// sorts most recently updated first
const transformData = (patientZendeskTickets): patientZdTicket[] =>
  patientZendeskTickets
    .map(ticket => ({
      subject: ticket.title || '',
      status: ticket.status || '',
      lastUpdated: ticket.lastUpdated ? new Date(ticket.lastUpdated) : new Date(),
      assignee: ticket.assigneeProvider || '',
      ticketLink: ticket?.zendeskLink || '',
    }))
    .sort(
      (a: patientZdTicket, b: patientZdTicket) => b.lastUpdated.getTime() - a.lastUpdated.getTime(),
    );

export const usePatientZendeskTickets = (patientId: string) => {
  const { data, error } = useTypedSWR([LOAD_PATIENT_ZENDESK_TICKETS, { patientId }]);

  const transformedTickets = useMemo(() => {
    if (!data?.staff_getPatientZendeskTickets) return [];
    return transformData(data?.staff_getPatientZendeskTickets);
  }, [data?.staff_getPatientZendeskTickets]);

  return {
    data: transformedTickets,
    error,
    loading: !data && !error,
  };
};
