import { gql } from 'src/generated/gql';

export const LOAD_PATIENT_ASSESSMENTS = gql(`
    query LoadPatientAssessments($patientId: ID!) {
        staff_getRecentlyCompletedPatientAssessments(patientId: $patientId) {
            patientId
            completedAt
            type
            assessmentId
        }
    }
`);
