import { generateRouteUrl } from 'src/boot/routes';
import { PatientAssessment } from 'src/generated/gql/graphql';

/**
 * Generates a URL for a patient assessment - depending on the type of assessment, the url will
 * point to different areas of the Staff app
 */
export function generateAssessmentUrl(assessment: PatientAssessment): string | undefined {
  switch (assessment.type) {
    case 'hrqol':
      return `${generateRouteUrl('showPatient', {
        id: assessment.patientId,
        tab: 'documents',
      })}#hrqol-${assessment.assessmentId}`;
    case 'phq9_fbpt':
    case 'social_background':
    case 'nutrition_fbpt':
      return `${generateRouteUrl('showPatient', {
        id: assessment.patientId,
        tab: 'activity',
      })}#activity-${assessment.assessmentId}`;
    default:
      return undefined;
  }
}
