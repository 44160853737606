import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import { useRouterStore } from 'mobx-state-router';
import React, { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import HrqolResults from 'src/components/forms/renderers/hrqolResults';
import ErrorAlert from 'src/components/general/ErrorAlert';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import EditChartDocumentDialog from 'src/components/pages/pageElements/editChartDocumentDialog';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import ConsentDocumentsTable from 'src/components/pages/patientShow/documents/ConsentDocumentsTable';
import DocumentsTable from 'src/components/pages/patientShow/documents/DocumentsTable';
import InsuranceRoiRequestsTable from 'src/components/pages/patientShow/documents/InsuranceRoiRequestsTable';
import { RequestTaskButton } from 'src/components/pages/patientShow/documents/RequestTaskButton';
import { getDisplayName } from 'src/components/pages/patientShow/documents/displayHelpers';
import Hrqol from 'src/components/pages/patientShow/documents/hrqol';
import TreatmentAgreement from 'src/components/pages/patientShow/documents/treatmentAgreementRequest';
import type {
  ChartDocument,
  PatientDocumentsResponse,
} from 'src/components/pages/patientShow/documents/types';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import ChartLoadingSpinner from 'src/nightingale/components/common/ChartLoadingSpinner/ChartLoadingSpinner';
import { VerifyIdentityControl } from 'src/patientVerification';
import { MESSAGE_CHART_DOCUMENT_TO_PATIENT } from 'src/stores/mutations/files';
import { LOAD_PATIENT_DOCUMENTS } from 'src/stores/queries/patients';

enum Action {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  Send = 'send',
}

const PatientDocuments: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const [action, setAction] = useState<Action | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<ChartDocument | null>(null);

  const styles = useStyles();

  const { apolloClient } = useContext(ApolloClientContext);

  const {
    data: patientDocuments,
    error: patientDocumentsError,
    mutate: mutatePatientDocuments,
  } = useSWR<PatientDocumentsResponse>(
    [
      LOAD_PATIENT_DOCUMENTS,
      {
        id: patientId,
      },
    ],
    { revalidateOnFocus: false },
  );
  const isPatientDocumentsLoading = !patientDocuments && !patientDocumentsError;

  const routerStore = useRouterStore();
  const { routerState } = routerStore;
  const updateQueryParams = (newParams: Record<string, string | undefined | null>) => {
    routerStore.goToState({
      ...routerState,
      queryParams: {
        ...routerState.queryParams,
        ...newParams,
      },
    });
  };
  useEffect(() => {
    if (isPatientDocumentsLoading) return;
    const { document, action: queryAction } = routerState.queryParams;
    if (document && queryAction) {
      const selectedDoc = patientDocuments?.chartDocumentsByPatient?.find(
        doc => doc.id === document,
      );
      if (selectedDoc) {
        handleAction(selectedDoc, queryAction as Action);
      }
    }
  }, [isPatientDocumentsLoading]);

  useEffect(() => {
    if (patientDocuments && window.location.hash?.startsWith('#hrqol-')) {
      document
        .getElementById(window.location.hash.slice(1))
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [patientDocuments]);

  const handleEditConfirmation = async newValues => {
    if (selectedDocument) {
      if (selectedDocument.id) {
        // Update
        await apolloClient?.mutate({
          mutation: gql`
            mutation ($data: ChartDocumentInput!, $id: ID!) {
              updateChartDocument(data: $data, id: $id) {
                id
              }
            }
          `,
          variables: {
            data: {
              title: newValues.title,
              type: newValues.type,
              date: newValues.date,
            },
            id: selectedDocument.id,
          },
        });
      } else {
        // Create
        await apolloClient?.mutate({
          mutation: gql`
            mutation ($data: ChartDocumentInput!) {
              createChartDocument(data: $data) {
                id
              }
            }
          `,
          variables: {
            data: {
              patient: patientId,
              file: newValues.file.id,
              title: newValues.title,
              type: newValues.type,
              date: newValues.date,
              description: newValues.description,
            },
          },
        });
      }
      mutatePatientDocuments();
    }
    clearAction();
  };

  const handleDeleteConfirmation = async () => {
    if (selectedDocument) {
      await apolloClient?.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deleteChartDocument(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: selectedDocument.id,
        },
      });
      mutatePatientDocuments();
    }
    clearAction();
  };

  const handleSendConfirmation = async () => {
    if (selectedDocument) {
      apolloClient?.mutate({
        mutation: MESSAGE_CHART_DOCUMENT_TO_PATIENT,
        variables: { id: selectedDocument.id },
      });
    }
    clearAction();
  };

  const handleRequestUmbrellaRoi = async () => {
    await apolloClient?.mutate({
      mutation: gql`
        mutation CreateUmbrellaRoiTask($patientId: ID!) {
          staff_createUmbrellaRoiTask(patientId: $patientId)
        }
      `,
      variables: {
        patientId,
      },
    });
    mutatePatientDocuments();
  };

  const handleAction = (selectedDoc: ChartDocument, actionType: Action) => {
    setSelectedDocument(selectedDoc);
    setAction(actionType);
    updateQueryParams({ document: selectedDoc.id ?? '', action: actionType });
  };

  const clearAction = () => {
    setSelectedDocument(null);
    setAction(null);
    updateQueryParams({ document: undefined, action: undefined });
  };

  return (
    <>
      {isPatientDocumentsLoading ? (
        <ChartLoadingSpinner message="Loading documents..." />
      ) : (
        <>
          <div
            className={classNames(styles.root, styles.documents)}
            data-testid="documents-section"
          >
            <TitleBar
              title="Documents"
              actionIcon={<AddIcon />}
              actionTooltip="Add Document"
              onAction={() => handleAction(createEmptyChartDocument(), Action.Edit)}
            />

            {patientDocumentsError ? (
              <ErrorAlert message="Error loading documents." error={patientDocumentsError} />
            ) : null}

            {patientDocuments?.chartDocumentsByPatient?.length ? (
              <>
                <VerifyIdentityControl patientId={patientId} />
                <DocumentsTable
                  chartDocuments={patientDocuments?.chartDocumentsByPatient}
                  onEdit={doc => handleAction(doc, Action.Edit)}
                  onDelete={doc => handleAction(doc, Action.Delete)}
                  onSelect={doc => handleAction(doc, Action.View)}
                  onSend={doc => handleAction(doc, Action.Send)}
                />
              </>
            ) : (
              <span className={styles.noneText}>None</span>
            )}
          </div>
          <div
            className={classNames(styles.root, styles.documentPageSection)}
            data-testid="roi-forms-section"
          >
            <TitleBar title="Release of Information (ROI) Forms" />
            <RequestTaskButton onClick={handleRequestUmbrellaRoi} text="Request Umbrella ROI" />
            {patientDocuments?.insuranceRoiRequest?.length ? (
              <InsuranceRoiRequestsTable
                insuranceRoiRequests={patientDocuments?.insuranceRoiRequest}
              />
            ) : null}
          </div>
          <>
            <div
              className={classNames(styles.root, styles.documentPageSection)}
              data-testid="oud-section"
            >
              <TitleBar title="Treatment Agreement" />
              <TreatmentAgreement
                patientId={patientId}
                treatmentAgreement={patientDocuments?.patient?.treatmentAgreement}
                onRequest={mutatePatientDocuments}
              />
            </div>
            <div
              className={classNames(styles.root, styles.documentPageSection)}
              data-testid="hrqol-section"
            >
              <TitleBar title="HRQoL Survey Responses" />
              <Hrqol />
              <HrqolResults
                completedHrqolSurveys={
                  patientDocuments?.hrqolSurveys?.filter(survey => Boolean(survey.completedAt)) ??
                  []
                }
              />
            </div>
          </>
          <div className={styles.root} data-testid="consent-forms-section">
            <TitleBar title="Patient Consent Forms" />
            {patientDocuments?.signedDocuments?.length ? (
              <ConsentDocumentsTable signedDocuments={patientDocuments?.signedDocuments} />
            ) : (
              <span className={styles.noneText}>None</span>
            )}
          </div>

          {action === Action.Edit && selectedDocument && (
            <EditChartDocumentDialog
              item={selectedDocument}
              onSave={handleEditConfirmation}
              onCancel={clearAction}
            />
          )}
          {action === Action.Delete && selectedDocument && (
            <ConfirmDialog
              isDestructive
              onSubmit={handleDeleteConfirmation}
              onCancel={clearAction}
              submitLabel="Delete"
            >
              Are you sure you want to delete this document?
            </ConfirmDialog>
          )}
          {action === Action.Send && selectedDocument && (
            <ConfirmDialog
              onSubmit={handleSendConfirmation}
              onCancel={clearAction}
              submitLabel="Send"
            >
              Are you sure you want to message this document to the patient? This link will expire
              after 24 hours.
            </ConfirmDialog>
          )}
          {action === Action.View && selectedDocument && (
            <Dialog
              data-testid="documents-dialog"
              fullWidth
              maxWidth="lg"
              onClose={clearAction}
              open
            >
              <DialogTitle className={styles.dialogTitle}>
                <span
                  className={styles.dialogTitleContainer}
                  data-testid="documents-dialog-title-container"
                >
                  {getDisplayName(selectedDocument)}
                  <IconButton onClick={clearAction}>
                    <CloseIcon />
                  </IconButton>
                </span>
              </DialogTitle>
              <DialogContent className={styles.dialogContent}>
                <iframe
                  width="100%"
                  height="100%"
                  className={styles.dialogIframe}
                  // Don't allow the toolbar to be displayed, as we're trying to discourage
                  // users from downloading/printing/sharing PHI
                  // See https://www.adobe.com/content/dam/acom/en/devnet/acrobat/pdfs/pdf_open_parameters.pdf
                  src={`${selectedDocument?.file?.url}#toolbar=0`}
                  title={(selectedDocument && getDisplayName(selectedDocument)) ?? 'Document'}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  root: {
    tableLayout: 'fixed',
  },
  documents: {
    marginBottom: 60,
  },
  documentPageSection: {
    marginBottom: 60,
  },
  noneText: {
    paddingLeft: 10,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogTitle: {
    // Default is 24px 24px 20px, but the IconButton on the right adds 20px
    // to the top, bottom, and right for us.
    padding: '4px 4px 4px 24px',
  },
  dialogTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    overflow: 'hidden',
  },
  dialogIframe: {
    height: 'calc(90vh - 96px)',
  },
});

function createEmptyChartDocument(): ChartDocument {
  return {
    date: format(new Date(), 'yyyy-MM-dd'),
    description: null,
    file: null,
    id: null,
    title: null,
    type: null,
  };
}

export const __test__ = { Action };

export default PatientDocuments;
