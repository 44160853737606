import { format, isValid, parseISO } from 'date-fns';
import { when } from 'mobx';
import { generateUrl, createRouterState, RouterStore as MobxRouterStore } from 'mobx-state-router';
import type { Route as MobxRoute, RouterState } from 'mobx-state-router';

import { DEFAULT_STATUSES } from 'src/claims/encounterSubmissions';
import type { Provider } from 'src/shared/stores/resource';
import type { RootStore } from 'src/stores/root';
import { queryParamsToUpdateArg as claimsQueryParamsToUpdateArg } from 'src/util/params/claims';
import { queryParamsToUpdateArg as patientsQueryParamsToUpdateArg } from 'src/util/params/patients';
import { queryParamsToUpdateArg as pebblesQueryParamsToUpdateArg } from 'src/util/params/pebbles';

const UNSAVED_CHANGES_WARNING = 'Leave page? Changes you made may not be saved.';
const routesThatCheckEditing = ['showPatient', 'showEvent', 'pebbles'];

// Our legacy transition hooks assume they're working with our specific RootStore, but the
// mobx-state-router typedefs don't provide a way to express that. These types override their MSR
// counterparts so we can access the MST stores in a typesafe way.
export class RouterStore extends MobxRouterStore {
  options: {
    rootStore: RootStore;
  };
}

type TransitionHook = (
  fromState: RouterState,
  toState: RouterState,
  routerStore: RouterStore,
) => Promise<RouterState | void>;

type Route = Omit<MobxRoute, 'beforeExit' | 'beforeEnter' | 'onExit' | 'onEnter'> & {
  beforeExit?: TransitionHook;
  beforeEnter?: TransitionHook;
  onExit?: TransitionHook;
  onEnter?: TransitionHook;
  noAuth?: boolean;
};

const checkAuth: TransitionHook = async (fromState, toState, routerStore) => {
  const {
    rootStore: { auth },
  } = routerStore.options;

  // Sleep until the auth store is initialized, since we can't check auth until it's loaded
  if (!auth.initialized) {
    await new Promise<void>(resolve => {
      when(() => auth.initialized, resolve);
    });
  }

  if (!auth.user) {
    auth.setAuthRedirect(toState);
    return createRouterState('login');
  }
  return undefined;
};

const fetchDataForPatientsList = async (rootStore, queryParams) => {
  const updateArg = patientsQueryParamsToUpdateArg(queryParams);
  await rootStore.patients.list.update(updateArg);
};

const fetchDataForProviderCalendar = async (
  rootStore,
  id: string,
  queryParams: {
    date?: string;
    view?: string;
  } = {},
) => {
  await rootStore.providers.load(id);

  const { date: dateString, view } = queryParams;
  const date = dateString ? parseISO(dateString) : new Date();
  await rootStore.providers.calendar.loadDateAndView(
    date && isValid(date) ? date : new Date(),
    view,
  );
};

const setDocumentTitleWithBadge = (documentTitle: string, rootStore: RootStore) => {
  const { auth, chat } = rootStore;

  let unreadMessageCount = 0;
  if ((auth?.user as Provider)?.unreadMessageNotifications === true && chat?.numWithUnread) {
    unreadMessageCount = chat.numWithUnread;
  }

  document.title = unreadMessageCount ? `${documentTitle} (${unreadMessageCount})` : documentTitle;
};

const prepareEvent = async (rootStore: RootStore): Promise<void> => {
  const { events: eventStore } = rootStore;
  const { event } = eventStore;
  if (!event) {
    throw new Error('Event not found');
  }

  // Initialize the visit notes for events that are supposed to have mandatory sections but for
  // some reason don't currently have them.
  // NOTE: Quick-fix for event instances in a recurrence
  await eventStore.setMandatorySections(event);

  // Prepare the patient for the visit notes
  const { patientAttendee } = event;
  if (patientAttendee) {
    await rootStore.patients.loadPatientOverview(patientAttendee.id);
  }
  // If the event does NOT have a patient attendee then explicitly unload any currently set
  // patient in the store so that things like the PatientOverviewLink don't inadvertently link
  // to the wrong patient.
  else if (rootStore.patients.patient) {
    rootStore.patients.unload();
  }

  setDocumentTitleWithBadge('Visit', rootStore);
};

const routes: Route[] = [
  {
    name: 'interstitialLandingPage',
    pattern: '/',
  },
  {
    name: 'login',
    pattern: '/login',
    noAuth: true,
  },
  {
    name: 'patients',
    pattern: '/patients',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      const { queryParams } = toState;
      await fetchDataForPatientsList(rootStore, queryParams);
      setDocumentTitleWithBadge('Patients', rootStore);
    },
  },
  {
    name: 'providers',
    pattern: '/providers',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      await rootStore.providers.list.load();
      setDocumentTitleWithBadge('Providers', rootStore);
    },
  },
  {
    name: 'createPatient',
    pattern: '/patients/create',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      await rootStore.patients.createPatient();
      setDocumentTitleWithBadge('Create Patient', rootStore);
    },
  },
  {
    name: 'patientExport',
    pattern: '/patients/:id/export',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Export', rootStore);
    },
  },
  {
    name: 'showPatient',
    pattern: '/patients/:id/:tab?/:tabItem?',
    onEnter: async (fromState, toState, routerStore) => {
      const { rootStore } = routerStore.options;
      const { tab, tabItem } = toState.params;

      if (tab === 'events') {
        const eventId = tabItem;
        await rootStore.events.loadEvent(eventId);
        await prepareEvent(rootStore);
        return;
      }

      let documentTitle;
      switch (tab) {
        case 'activity':
          documentTitle = 'Activity';
          break;
        case 'calendar':
          documentTitle = 'Calendar';
          break;
        case 'documents':
          documentTitle = 'Documents';
          break;
        case 'conversation':
          documentTitle = 'Chat';
          break;
        case 'tasks':
          documentTitle = 'Tasks';
          break;
        case 'clinicActions':
          documentTitle = 'Clinic';
          break;
        case 'erx':
          documentTitle = 'E-Rx';
          break;
        case 'pebbles':
          documentTitle = 'Pebbles';
          break;
        case 'pocTesting':
          documentTitle = 'POC Testing';
          break;
        case 'clinicalSnapshot':
          documentTitle = 'Clinical Snapshot';
          break;
        case 'overview':
        default:
          documentTitle = 'Overview';
          break;
      }

      setDocumentTitleWithBadge(documentTitle, rootStore);
    },
  },
  {
    name: 'editProvider',
    pattern: '/providers/:id/edit',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      await rootStore.providers.load(toState.params.id);
      setDocumentTitleWithBadge('Edit Provider', rootStore);
    },
  },
  {
    name: 'createProvider',
    pattern: '/providers/create',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      await rootStore.providers.createProvider();
      setDocumentTitleWithBadge('Create Provider', rootStore);
    },
  },
  {
    name: 'showProvider',
    pattern: '/providers/:id/:tab?',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      await fetchDataForProviderCalendar(rootStore, toState.params.id, toState.queryParams);
      const calendarTitle =
        toState.params.id === rootStore.auth.user?.id ? 'My Calendar' : 'Calendar';
      setDocumentTitleWithBadge(calendarTitle, rootStore);
    },
  },
  {
    name: 'showEvent',
    pattern: '/events/:id',
    onEnter: async (fromState, toState, routerStore) => {
      const {
        options: { rootStore },
      } = routerStore;
      const { events: eventStore } = rootStore;
      await eventStore.loadEvent(toState.params.id);
      const { event } = eventStore;

      if (!event) {
        throw new Error('Event not found');
      }

      const patient = event.patientAttendee;
      if (patient) {
        // Events containing a patient are rendered within the patient page so we can render them
        // with the patient menu.
        routerStore.goTo('showPatient', {
          params: {
            id: patient.id,
            tab: 'events',
            tabItem: event.id,
          },
        });
      }

      await prepareEvent(rootStore);
    },
  },
  {
    name: 'inquiries',
    pattern: '/inquiries',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      await rootStore.events.loadInquiries();
      setDocumentTitleWithBadge('Inquiries', rootStore);
    },
  },
  {
    name: 'chat',
    pattern: '/chat',
    onEnter: async (
      fromState,
      toState,
      {
        options: {
          rootStore,
          rootStore: { chat, providers },
        },
      },
    ) => {
      await Promise.all([chat.loadAllConversations(), providers.load(chat.currentUser.id)]);
      setDocumentTitleWithBadge('My Chat', rootStore);
    },
  },
  {
    name: 'chatConversation',
    pattern: '/chat/:conversation',
    onEnter: async (
      fromState,
      toState,
      {
        options: {
          rootStore,
          rootStore: { chat, providers },
        },
      },
    ) => {
      await Promise.all([
        chat.selectConversation(toState.params.conversation),
        providers.load(chat.currentUser.id),
      ]);
      setDocumentTitleWithBadge('My Chat', rootStore);
    },
  },
  {
    name: 'chatVc',
    pattern: '/chat/:conversation/:message',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('My Chat', rootStore);
    },
  },
  {
    name: 'claims',
    pattern: '/claims',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      const { queryParams } = toState;

      const updateArg = claimsQueryParamsToUpdateArg(queryParams);
      await rootStore.claims.list.update(updateArg);
      setDocumentTitleWithBadge('Claims', rootStore);
    },
  },
  {
    name: 'pebble',
    pattern: '/pebble/:id',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Pebble', rootStore);
    },
  },
  {
    name: 'pebbles',
    pattern: '/pebbles',
    onEnter: async (fromState, toState, routerStore) => {
      const { queryParams } = toState;
      const { rootStore } = routerStore.options;

      const updateArg = pebblesQueryParamsToUpdateArg(queryParams);
      await rootStore.pebbles.domain.list.update(updateArg);
      setDocumentTitleWithBadge('Pebbles', rootStore);
    },
  },
  {
    name: 'eventVc',
    pattern: '/events/:event/video',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      const { events: eventStore } = rootStore;
      await eventStore.loadEvent(toState.params.event);
      setDocumentTitleWithBadge('Video', rootStore);
    },
  },
  {
    name: 'automation',
    pattern: '/automation',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Automations', rootStore);
    },
  },
  {
    name: 'notFound',
    pattern: '/not-found',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Boulder Staff', rootStore);
    },
  },
  {
    name: 'unsupportedBrowser',
    pattern: '/unsupportedBrowser',
    noAuth: true,
    onEnter: async () => {
      document.title = 'Boulder Staff';
    },
  },
  {
    name: 'erx',
    pattern: '/erx',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('E-Rx', rootStore);
    },
  },
  {
    name: 'encounterSubmissions',
    pattern: '/encounterSubmissions',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      const { routerStore } = rootStore;
      // Defaults to claims submitted today with status 'held', 'needs-update', or 'rejected'
      routerStore.goTo('encounterSubmissions', {
        queryParams: {
          createdAt: toState.queryParams.createdAt ?? format(new Date(), 'yyyy-MM-dd'),
          state: toState.queryParams.state ?? DEFAULT_STATUSES,
        },
      });
      setDocumentTitleWithBadge('Boulder Staff', rootStore);
    },
  },
  {
    name: 'patientSubmissions',
    pattern: '/patientSubmissions',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Boulder Staff', rootStore);
    },
  },
  {
    name: 'dropInClinicRequests',
    pattern: '/dropInClinicRequests',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Pop-In Queue', rootStore);
    },
  },
  {
    name: 'dropInClinicScheduleLogs',
    pattern: '/popInClinic/scheduleLogs',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Pop-In Schedule Logs', rootStore);
    },
  },
  {
    name: 'sendTexts',
    pattern: '/sendTexts',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Send Texts', rootStore);
    },
  },
  {
    name: 'myDayToday',
    pattern: '/myDayToday/:id',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Home', rootStore);
    },
  },
  {
    name: 'waitlistPromotion',
    pattern: '/waitlistPromotion',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Waitlist Promotion', rootStore);
    },
  },
  {
    name: 'zendeskPatient',
    pattern: '/zd/patients/:zendeskExternalId',
    onEnter: async (fromState, toState, { options: { rootStore } }) => {
      setDocumentTitleWithBadge('Zendesk Patient', rootStore);
    },
  },
];

const updatedRoutes = routes.map(route => {
  const updatedRoute = route;
  if (updatedRoute.noAuth !== true) {
    updatedRoute.beforeEnter = checkAuth;
  }

  if (routesThatCheckEditing.includes(route.name)) {
    // If we see something was left in an unsaved state, prompt the
    // user to confirm they want to leave before navigating.
    updatedRoute.beforeExit = (fromState, toState, routerStore) => {
      if (routerStore.options.rootStore.isEditing) {
        /* eslint-disable-next-line no-alert, no-restricted-globals */
        if (!confirm(UNSAVED_CHANGES_WARNING)) {
          return Promise.resolve(fromState);
        }
      }
      return Promise.resolve();
    };
    // Make sure we clean up after ourselves when we leave
    updatedRoute.onExit = (fromState, toState, routerStore) => {
      routerStore.options.rootStore.clearEditing();
      return Promise.resolve();
    };
  }

  return updatedRoute;
});

export function generateRouteUrl(
  routeName: string,
  routeParams?: Record<string, unknown>,
  queryStringParams?: Record<string, unknown>,
) {
  const routeToGenerate = updatedRoutes.find(route => route.name === routeName);
  if (!routeToGenerate) {
    throw new Error(`no such route as '${routeName}'`);
  }
  return generateUrl(routeToGenerate.pattern, routeParams, queryStringParams);
}

export default updatedRoutes;

export const __test__ = {
  setDocumentTitleWithBadge,
};
