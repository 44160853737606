import { gql } from 'src/generated/gql';

export const LOAD_DRUG_SCREENING = gql(`
  query LoadDrugScreeningData($patientId: ID!) {
    staff_getPatientDrugScreeningData(patientId: $patientId) {
      patientId
      status
      currentPeriod
      dueDate
      hasConflictingData
      mitigation
      testKitOrders {
        testType
        statusDate
        status
      }
    }
  }
`);
