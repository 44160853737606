/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';

import { Colors } from 'src/common/ui/base/Colors';
import { Spacing } from 'src/common/ui/base/Spacing';
import { TypographyStyles } from 'src/common/ui/base/Typography';

const copyStyle = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${TypographyStyles.subheader2}
  }

  &[data-variant='body2'],
  &[data-variant='body3'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${TypographyStyles.subheader3}
    }
  }
  b,
  strong {
    font-weight: 700;
  }
  i,
  em {
    font-style: italic;
  }
  blockquote,
  figure,
  pre,
  p,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  table {
    margin: 0;
    margin-bottom: ${Spacing.Unit2}px;
    padding: 0;
  }
  ul,
  ol,
  dl {
    margin-left: ${Spacing.Unit4}px;
  }
  dt {
    font-weight: 700;
  }
  table {
    &,
    tr,
    th,
    tbody,
    caption,
    td {
      border-collapse: collapse;
      border: 1px solid ${Colors.CoolGray4};
    }
    th,
    caption {
      font-weight: 700;
      text-align: left;
    }
    caption,
    th,
    td {
      padding: ${Spacing.Unit1}px;
    }
    caption {
      border-bottom: 0;
    }
  }
  img {
    max-width: 100%;
    margin: 0 auto ${Spacing.Unit2}px;
  }
  a {
    &:link,
    &:visited {
      color: ${Colors.Blue4};
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: 1px dotted ${Colors.Blue4};
      }
    }
  }
  address {
    font-style: normal;
  }
  blockquote {
    border-left: ${Spacing.Unit2}px solid ${Colors.CoolGray3};
    padding: 0 ${Spacing.Unit2}px;

    cite {
      display: block;
      margin-top: ${Spacing.Unit2}px;
    }
  }
  hr {
    border: 0;
    border-top: 1px solid ${Colors.CoolGray4};
    margin: ${Spacing.Unit4}px 0;
  }
  pre {
    padding: ${Spacing.Unit2}px;
    background-color: ${Colors.CoolGray1};
    color: ${Colors.CoolGray8};
    font-family: ui-monospace, 'Menlo', 'Monaco', 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
      'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
      'Courier New', monospace;
    font-size: 0.9em;
  }
  figcaption {
    font-style: italic;
  }
`;

/**
 * Basic copy component for body text. Takes a style prop to determine the base font size.
 *
 * @param style - The style of the copy.
 */
export const Copy: React.FC<{
  variant?: 'body1' | 'body2' | 'body3';
}> = ({ children, variant = 'body1' }) =>
  jsx(
    'div',
    {
      css: css`
        ${TypographyStyles[variant]}
        ${copyStyle}
      `,
      'data-testid': 'nds-component-copy',
      'data-variant': variant,
    },
    children,
  );
