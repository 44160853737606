import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { useVisitReason } from 'src/components/forms/hooks/useVisitReason';
import { VisitReason } from 'src/dropInClinic/visitReason/VisitReason';
import { AttendeeList } from 'src/events/components/AttendeeList';
import { PatientAssessments } from 'src/events/components/PatientAssessments';
import { useStyles as usePreVisitDisplayStyles } from 'src/events/styles/preVisitDisplay.styles';
import { EventInstance } from 'src/stores/models/event';

export const ViewEventDetails: React.FC<{
  event: EventInstance;
}> = ({ event: { id, attendees, signedAt } }) => {
  const visitReason = useVisitReason(id);

  const preVisitDisplayStyles = usePreVisitDisplayStyles();
  const styles = useStyles();

  const providers = attendees.filter(attendee => attendee.__typename === 'Provider');
  const patient = attendees.find(attendee => attendee.__typename === 'Patient');

  // A visit is complete once the event signedAt value is populated
  const isVisitComplete = !!signedAt;

  return (
    <>
      {!!providers.length && <AttendeeList attendees={providers} />}

      {visitReason && (
        <div className={preVisitDisplayStyles.sectionContainer}>
          <div className={preVisitDisplayStyles.sectionLabel}>Visit Reason</div>
          <div className={styles.listContainer}>
            <VisitReason visitReasonDetails={visitReason.details} />
          </div>
        </div>
      )}

      <PatientAssessments patientId={patient?.id} isVisitComplete={isVisitComplete} />
    </>
  );
};

const useStyles = makeStyles({
  listContainer: {
    '& ul': {
      marginTop: 5,
      fontSize: '0.875rem',
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
});
