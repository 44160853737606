enum PatientMenuTabValue {
  Overview = 'overview',
  ClinicalSnapshot = 'clinicalSnapshot',
  Activity = 'activity',
  Calendar = 'calendar',
  Events = 'events',
  PocTesting = 'pocTesting',
  Documents = 'documents',
  Zendesk = 'zendesk',
  Conversation = 'conversation',
  Pebbles = 'pebbles',
  Tasks = 'tasks',
  ClinicActions = 'clinicActions',
  Erx = 'erx',
}

export default PatientMenuTabValue;
